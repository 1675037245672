import { createApp } from 'vue';
import i18n from '@/locales/i18n';

import Toast, { PluginOptions, POSITION } from 'vue-toastification';
import * as Sentry from '@sentry/vue';

import App from './App.vue';
import router from './router';
import { store } from '@/mw-components/store';
import './scss/main.scss';
import 'vue-toastification/dist/index.css';
import { getEnv } from '@/plugin/functions';

const mw = createApp(App);

const options: PluginOptions = {
  position: POSITION.BOTTOM_RIGHT,
  timeout: 5000,
};

console.log(`Vite Env: ${import.meta.env.MODE}
  Run Env: ${getEnv()}`);

/**
 * Configure sentry for error reporting on production & development
 * "mw-app" project. @see https://sentry.io/organizations/liftsharecom-ltd/projects/mw-app/?project=5900508
 */
Sentry.init({
  app: mw,
  environment: getEnv(),
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  tracesSampleRate: 0.1,
  tracePropagationTargets: [
    'https://interface.liftshare.com',
    'https://interface-dev.liftshare.com',
    'https://interface-staging.liftshare.com',
    import.meta.env.services__liftshare_api__https__0
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: import.meta.env.MODE === 'production',
});

mw.use(router);
mw.use(store);
mw.use(Toast, options);
mw.use(i18n);
mw.mount('#app');
